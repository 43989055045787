/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		'common': {
			init: function () {
				// JavaScript to be fired on all pages

				$("#header-logo-svg-full rect").hover(
					function() {
						$(this).siblings("#header-logo-full").attr("fill", "#FFF");
					},
					function() {
						$(this).siblings("#header-logo-full").attr("fill", "#D0D0CE");
					}
				);

				$("#event-nav-dropdown-toggle").hover(
					function() {
						$(this).find("#event-nav-dropdown-toggle-icon").addClass("hover");
					},
					function() {
						$(this).find("#event-nav-dropdown-toggle-icon").removeClass("hover");
					}
				);

				var bannerSearchFieldOpen = false;

				$('.menu-search .dropdown-toggle').click(function() {

					if (!bannerSearchFieldOpen) {
						$(this).children().addClass('open');
						$('.menu-search .dropdown-search-form').addClass('open');
					} else {
						$(this).children().removeClass('open');
						$('.menu-search .dropdown-search-form').removeClass('open');
					}

					bannerSearchFieldOpen = !bannerSearchFieldOpen;

				});

				var eventNavDropdownOpen = false;

				$('#event-nav-dropdown-toggle').click(function() {

					if (!eventNavDropdownOpen) {
						$('#event-nav-dropdown-toggle, #event-nav-dropdown-wrap').addClass('open');
					} else {
						$('#event-nav-dropdown-toggle, #event-nav-dropdown-wrap').removeClass('open');
					}

					eventNavDropdownOpen = !eventNavDropdownOpen;

				});

				var headerElements = $('header .header-banner-wrap, header .banner, .event-nav-wrap, #event-nav-dropdown-toggle');
				var eventNavContainer = $('.event-nav-container');
				var headerBannerHeight = $('header .banner').outerHeight(true);
				var eventNavWrapHeight = $('.event-nav-wrap').outerHeight(true);

				$(window).on("scroll", function() {
					if (headerBannerHeight < $(window).scrollTop()) {
						headerElements.addClass('nav-scroll');
						eventNavContainer.css('height', eventNavWrapHeight);
					} else {
						headerElements.removeClass('nav-scroll');
						eventNavContainer.css('height', 'auto');
					}
				});

				if (headerBannerHeight < $(window).scrollTop()) {
					headerElements.addClass('nav-scroll');
					headerElements.css('transition', 'none');
					eventNavContainer.css('height', eventNavWrapHeight);
					setTimeout(function() {
						headerElements.css('transition', '');
					}, 0);
				}

				$("#footer-logo-svg rect").hover(
					function() {
						$(this).siblings(".footer-logo-initials").attr("fill", "#FFF");
					},
					function() {
						$(this).siblings(".footer-logo-initials").attr("fill", "#D0D0CE");
					}
				);

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired

				$('body').css('opacity', '1');

			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page

				var eventTitleBar = $('#event-title-bar');
				var headerBannerToTitleMetaBlockHeight = $('.header-banner-wrap').outerHeight(true) + $('.event-title-meta-block .event-title-prefix').outerHeight(true) + $('.event-title-meta-block .event-title').outerHeight(true) - 16;

				$(window).on("scroll", function() {
					if (headerBannerToTitleMetaBlockHeight < $(window).scrollTop()) {
						eventTitleBar.addClass('nav-scroll');
					} else {
						eventTitleBar.removeClass('nav-scroll');
					}
				});

				if (headerBannerToTitleMetaBlockHeight < $(window).scrollTop()) {
					eventTitleBar.addClass('nav-scroll');
					eventTitleBar.css('transition', 'none');
					setTimeout(function() {
						eventTitleBar.css('transition', '');
					}, 0);
				}

				$(".event-home-header .cta-btn").each(function(i){
					$(this).addClass("jumbo-banner-btn-" + ( i + 1 ));
				});

			}
		},
		'page_template_page_event_venue' : {
			init: function(){
				// JavaScript to be fired on the event venue page

				
			}
		},
		// Agenda page
		'page_template_page_event_agenda': {
			init: function () {
				// JavaScript to be fired on the event agenda page

				$('.agenda-tab-group a[data-toggle]').on("click", function(){
					$(".agenda-tab.active").removeClass("active")
					$(this).toggleClass("active");
				});

				$('#expand-all-toggle').on('click', function(){
					$('.panel-collapse:not(".in")').collapse('show');
				});
			
				$('#collapse-all-toggle').on('click', function(){
					$('.panel-collapse.in').collapse('hide');
				});

				$('.panel').on('show.bs.collapse', function(){
					$(this).parents('.session-details').find('.expand-collapse-icon').addClass('rotate');
				});

				$('.panel').on('hide.bs.collapse', function(){
					$(this).parents('.session-details').find('.expand-collapse-icon').removeClass('rotate');
				});

				$(document).on('click', '.line-item-container.accordion-toggle .session-content a', function(e){
					e.stopPropagation();
				});

				// Get all the collapse elements that are not currently shown
				var $collapses = $('.panel-collapse:not(".in")');

				// Initialize a counter to track how many collapses have completed
				var totalCollapsed = $collapses.length;
				var collapsedCount = 0;

				// Attach the event handler to each collapse element
				$collapses.on('shown.bs.collapse', function () {
					collapsedCount++;
				if (collapsedCount === totalCollapsed) {
					// All collapses have completed showing
					$('.page-template-page-event-agenda').css('opacity', '1');
				}
				});

				// Trigger the show action on all collapse elements
				$collapses.collapse('show');

				if($collapses.length === 0){
					$('.page-template-page-event-agenda').css('opacity', '1');
				}

				window.onbeforeprint = function() {
					Tawk_API.hideWidget();
				};

				window.onafterprint = function() {
					Tawk_API.showWidget();
				};

				$('#print-agenda').on('click', function() {
					window.print();
				});

			}
		},
		// Agenda preview page
		'page_template_page_event_agenda_preview': {
			init: function() {
				// Javascript to be fired on the event agenda preview page

				initializeSmartForm();

			}
		},
		// Webinar page
		'page_template_page_event_webinar': {
			init: function() {
				// Javascript to be fired on the event webinar page

				initializeSmartForm();

			}
		},
		// Landing page
		'page_template_page_event_landing': {
			init: function() {
				// Javascript to be fired on the event landing page

				initializeLandingPage();
				$('.page-template-page-event-landing').css('opacity', '1');

			}
		},
		// Landing download page
		'page_template_page_event_landing_download': {
			init: function() {
				// Javascript to be fired on the event landing download page

				initializeLandingPage();
				$('.page-template-page-event-landing-download').css('opacity', '1');

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

	var initializeSmartForm = function() {

		$('html').css('scroll-behavior', 'smooth');

		$(document).on('nfFormReady', function(){

			// Move Canada, United States and United Kingdom to top of Country selection list
			var companySite = /\.(.+)/.exec(window.location.hostname)[1];
			
			if (companySite == 'canadianinstitute.com') {
				$($('.smart-form .listcountry-wrap option[value="CA"]')).insertAfter('.smart-form .listcountry-wrap select option:first-of-type');
				$($('.smart-form .listcountry-wrap option[value="US"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(2)');
				$($('.smart-form .listcountry-wrap option[value="GB"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(3)');
			}
			if (companySite == 'americanconference.com') {
				$($('.smart-form .listcountry-wrap option[value="US"]')).insertAfter('.smart-form .listcountry-wrap select option:first-of-type');
				$($('.smart-form .listcountry-wrap option[value="CA"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(2)');
				$($('.smart-form .listcountry-wrap option[value="GB"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(3)');
			}
			if (companySite == 'c5-online.com') {
				$($('.smart-form .listcountry-wrap option[value="GB"]')).insertAfter('.smart-form .listcountry-wrap select option:first-of-type');
				$($('.smart-form .listcountry-wrap option[value="US"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(2)');
				$($('.smart-form .listcountry-wrap option[value="CA"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(3)');
			}

			// Configure tab index
			$('.smart-form input[name="fname"]').attr('tabindex', '2');
			$('.smart-form input[name="lname"]').attr('tabindex', '3');
			$('.smart-form input[name="position"]').attr('tabindex', '4');
			$('.smart-form input[name="company"]').attr('tabindex', '5');
			$('.smart-form .listcountry-wrap select').attr('tabindex', '6');
			$('.smart-form input[name="email"]').attr('tabindex', '7');
			$('.smart-form input[name="business_phone"]').attr('tabindex', '8');
			$('.smart-form input[name="mobile_phone"]').attr('tabindex', '9');

			// Email consent conditional logic
			var consentCountries = ['AU', 'CH', 'CA', 'GB', 'AT', 'BE', 'CY', 'DE', 'DK', 'ES', 'FI', 'FR', 'GR', 'IE', 'IT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SE', 'BG', 'CZ', 'EE', 'HR', 'HU', 'LT', 'LV', 'PL', 'RO', 'SI', 'BR', 'NZ'];
			var gdprCountries = ['AU', 'CH', 'GB', 'AT', 'BE', 'CY', 'DE', 'DK', 'ES', 'FI', 'FR', 'GR', 'IE', 'IT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SE', 'BG', 'CZ', 'EE', 'HR', 'HU', 'LT', 'LV', 'PL', 'RO', 'SI', 'BR', 'NZ'];
			var emailConsentFieldID = $('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container .field-wrap').data('field-id');

			nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('visible', false).set('required', 0);

			$('.smart-form').on('input propertychange paste', '.listcountry-container select, .email-container input', function() {

				var reqConsent = true; // This value being set to 'true' is currently overriding the consent countries check and requiring consent for all countries. To enable default behaviour (only countries in the consentCountries array require consent), change this value to 'false'.
				var forceConsent = true;
				var submitHoverTriggered = false;

				$.each(consentCountries, function(i, v) {

					if ($('.smart-form .listcountry-container select').val() === v) {
						reqConsent = true;
						return false;
					}

				});

				$.each(gdprCountries, function(i, v) {

					if ($('.smart-form .listcountry-container select').val() === v) {
						forceConsent = false;
						submitHoverTriggered = false;
						return false;
					} else {
						submitHoverTriggered = true;
					}

				});

				if ($('.smart-form .email-container input').val() != '' && reqConsent) {

					nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('visible', true);
					$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container input').prop('checked', false).trigger('change');
					nfRadio.channel('fields').request('remove:error', emailConsentFieldID, 'required-error');

					if(forceConsent) {
						nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('required', 1);
					} else {
						nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('required', 0);
					}

				} else {

					nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('visible', false).set('required', 0);
					$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container input').prop('checked', false).trigger('change');
					nfRadio.channel('fields').request('remove:error', emailConsentFieldID, 'required-error');

				}

				$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container input').off('change.gdprCountry');
				$('.smart-form .consent-not-required-msg').remove();

				$.each(gdprCountries, function(i, v) {

					if ($('.smart-form .listcountry-container select').val() === v) {

						$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container input').on('change.gdprCountry', function() {

							if (this.checked) {
								$('.smart-form .consent-not-required-msg').remove();
							} else {
								if ($('.smart-form .consent-not-required-msg').length === 0) {
									$('<div class="consent-not-required-msg">Thank you for providing us with your email address for this request. Don’t miss out on special offers, event updates and exclusive content, check the above now to provide consent.</div>').insertAfter($('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container .nf-after-field nf-section .nf-input-limit'));
								}
							}
							submitHoverTriggered = true;

						});

						return false;
						
					}

				});

				$('.smart-form .nf-field-container.submit-container').on('mouseenter', function() {

					if (!submitHoverTriggered && $('.smart-form .email-container input').val() != '') {
						if ($('.smart-form .consent-not-required-msg').length === 0) {
							$('<div class="consent-not-required-msg">Thank you for providing us with your email address for this request. Don’t miss out on special offers, event updates and exclusive content, check the above now to provide consent.</div>').insertAfter($('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container .nf-after-field nf-section .nf-input-limit'));
						}
						$(window).scrollTop($('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4)').offset().top - 150);
						submitHoverTriggered = true;
					}
	
				});

			});

		});

	}

	var initializeLandingPage = function() {

		$('.event-breadcrumb-callout, header.navbar-default, #main-site-slide-in-nav, .event-nav-wrap, .event-title-bar, #event-contact-block, #mailing-list-cta, footer, .share-event-btns-wrap').remove();

		var eventTitleComponent = $('.landing-header .event-title, .landing-download-header .event-title');
		var eventTitlePrefixComponent = $('.landing-header .event-title-prefix, .landing-download-header .event-title-prefix');
		var eventDateComponent = $('.landing-header .event-date, .landing-download-header .event-date');
		var eventLocationComponent = $('.landing-header .event-location, .landing-download-header .event-location');

		if (eventTitleComponent.text() == '' && eventTitleComponent.html() == '') {
			eventTitleComponent.html($.parseHTML(eventTitle));
		}
		if (eventTitlePrefixComponent.text() == '' && eventTitlePrefixComponent.html() == '') {
			eventTitlePrefixComponent.html($.parseHTML(eventTitlePrefix));
		}
		if (eventDateComponent.text() == '' && eventDateComponent.html() == '') {
			eventDateComponent.html($.parseHTML(eventDate));
		}
		if (eventLocationComponent.text() == '' && eventLocationComponent.html() == '') {
			eventLocationComponent.html($.parseHTML(eventLocation));
		}

		$(document).on('nfFormReady', function() {

			// Move Canada, United States and United Kingdom to top of Country selection list
			var companySite = /\.(.+)/.exec(window.location.hostname)[1];

			if (companySite == 'canadianinstitute.com') {
				$($('.listcountry-wrap option[value="CA"]')).insertAfter('.listcountry-wrap select option:first-of-type');
				$($('.listcountry-wrap option[value="US"]')).insertAfter('.listcountry-wrap select option:nth-of-type(2)');
				$($('.listcountry-wrap option[value="GB"]')).insertAfter('.listcountry-wrap select option:nth-of-type(3)');
			}
			if (companySite == 'americanconference.com') {
				$($('.listcountry-wrap option[value="US"]')).insertAfter('.listcountry-wrap select option:first-of-type');
				$($('.listcountry-wrap option[value="CA"]')).insertAfter('.listcountry-wrap select option:nth-of-type(2)');
				$($('.listcountry-wrap option[value="GB"]')).insertAfter('.listcountry-wrap select option:nth-of-type(3)');
			}
			if (companySite == 'c5-online.com') {
				$($('.listcountry-wrap option[value="GB"]')).insertAfter('.listcountry-wrap select option:first-of-type');
				$($('.listcountry-wrap option[value="US"]')).insertAfter('.listcountry-wrap select option:nth-of-type(2)');
				$($('.listcountry-wrap option[value="CA"]')).insertAfter('.listcountry-wrap select option:nth-of-type(3)');
			}

			// Configure tab index
			$('input[name="fname"]').attr('tabindex', '2');
			$('input[name="lname"]').attr('tabindex', '3');
			$('input[name="company"]').attr('tabindex', '4');
			$('input[name="position"]').attr('tabindex', '5');
			$('input[name="email"]').attr('tabindex', '6');
			$('.listcountry-wrap select').attr('tabindex', '7');

		});

		// Remove Tawk.to widget
		$(window).load(function() {

			Tawk_API.onLoad = function() { Tawk_API.hideWidget(); };

		});

	}

	var debounce = function(func, wait) {

		var timeout;

		return function() {

			var context = this, args = arguments;

			var later = function() {
				timeout = null;
				func.apply(context, args);
			};

			clearTimeout(timeout);

			timeout = setTimeout(later, wait);
		};

	}

})(jQuery); // Fully reference jQuery after this point.
